import React from 'react'

const PreviewContext = React.createContext()
const PreviewAssetsContext = React.createContext()
const CognitoContext = React.createContext()
const AuthenticationContext = React.createContext()
const NProgressContext = React.createContext()
const SubnavContext = React.createContext()
const BladeIntersectionContext = React.createContext()
const LocaleContext = React.createContext()
const VideoLoopContext = React.createContext()

export {
  PreviewContext,
  PreviewAssetsContext,
  CognitoContext,
  AuthenticationContext,
  NProgressContext,
  SubnavContext,
  BladeIntersectionContext,
  LocaleContext,
  VideoLoopContext,
}
