import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const useErrorFocus = () => {
  // For formik form, if there is an error hook will focus on first error input
  const { errors, isSubmitting, isValidating } = useFormikContext() || {}

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors)
      if (keys.length > 0) {
        // Modify selector if there are other fields ie) text area etc.
        const selector = `input[name="${keys[0]}"]`
        const errorElement = document.querySelector(selector)
        if (errorElement) {
          errorElement.focus()
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

export default useErrorFocus
