import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './client'
import { AuthenticationProvider } from '../../common/hooks/useLogin'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AuthenticationProvider>{element}</AuthenticationProvider>
  </ApolloProvider>
)
