// TO ADD
import { useState, useEffect, useRef } from 'react'
import videojs from 'video.js'

export const useVideoPlayer = ({
  src,
  thumbnail,
  modal,
  loop = false,
  autoplay = false,
  aspectRatio = '16:9'
}) => {
  const videoRef = useRef(null)
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      controls: !loop && !autoplay,
      autoplay: (autoplay || loop) && 'muted',
      loop,
      aspectRatio: aspectRatio || '16:9',
      controlBar: {
        captionsButton: true,
        pictureInPictureToggle: false,
        subsCapsButton: true,
      },
      poster: thumbnail,
      sources: [
        {
          src: src,
          type: 'video/mp4',
        },
      ],
      userActions: modal && {
        hotkeys: e => {
          const { modalRef, closePortal } = modal || {}
          const focusableModalElements = 'button'

          const modalElements = Array.from(
            modalRef.current.querySelectorAll(focusableModalElements)
          ).filter(m => m.offsetParent)

          const firstButton = modalElements[0]
          const lastButton = modalElements[modalElements.length - 1]

          if (e.code === 'Tab' || e.which === 9 || e.keyCode === 9) {
            if (!e.shiftKey && e.target === lastButton) {
              e.preventDefault()
              firstButton.focus()
            }
          }

          if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
            closePortal()
          }
        },
      },
    })

    setPlayer(vjsPlayer)

    return () => {
      if (player) {
        player.dispose()
      }
    }
  }, [])

  return {
    videoRef,
    player,
  }
}
