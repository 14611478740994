module.exports = {
  url: 'http://www.xboxreviewersguide.com', // do no include trailing backslash
  postsPerHomePage: 7,
  postsPerPage: 6,
  authorDefaultEmail: 'contact@xboxreviewersguide.com',
  author: 'Team Xbox', // author for RSS / SEO schema
  authorURL: 'http://www.xboxreviewersguide.com', // url for author/publisher schema
  twitterHandle: '@Xbox', // for twitter cards
  shortTitle: 'xboxreviewersguide.com', // for app manifest
  image: 'icon.png', // OG default share image, 1200 x 1200 recommended
  imageWidth: 1200, // change to actual width of share image
  imageHeight: 1200, // ditto actual height
  logo: 'favicon.png', // logo for SEO, RSS, and app manifest
  logoAlt: 'xboxreviewersguide.com',
  backgroundColor: '#e9e9e9', // offline manifest
  themeColor: '#121212', // offline manigest
  copyright: 'Copyright © 2020 Xbox', // copyright for RSS feed
  publisher: 'xboxreviewersguide.com',
  shareImage: 'icon.png', // OG default share image, 1200 x 1200 recommended
  shareImageWidth: 1200, // change to actual width of share image
  shareImageHeight: 1200, // ditto actual height
  titleAlt: 'Xbox Series X | S Review Guide',
  title: 'Xbox Series X | S Review Guide',
  description: 'xboxreviewersguide.com',
  theme: 'black',
  locales: [
    {
      language: 'Spanish',
      region: 'Spain',
      locale: 'es-ES',
    },
    {
      language: 'Spanish',
      region: 'LATAM',
      locale: 'es-419',
    },
    {
      language: 'French',
      region: 'France',
      locale: 'fr-FR',
    },
    {
      language: 'French',
      region: 'Canadian',
      locale: 'fr-CA',
    },
    {
      language: 'German',
      region: 'Germany',
      locale: 'de-DE',
    },
    {
      language: 'Italian',
      region: 'Italy',
      locale: 'it-IT',
    },
    {
      language: 'Japanese',
      region: 'Japan',
      locale: 'ja-JP',
    },
    {
      language: 'Portuguese',
      region: 'Brazilian',
      locale: 'pt-BR',
    },
    {
      language: 'Korean',
      region: 'Korea',
      locale: 'ko-KR',
    },
    {
      language: 'Swedish',
      region: 'Sweden',
      locale: 'sv-SE',
    },
  ],
}
