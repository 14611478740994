import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
const accessToken = process.env.GATSBY_CONTENTFUL_PREVIEW_API

const accessPath = `access_token=${accessToken}`
const basePath = `/environments/${environment}`
const entriesPath = `${basePath}/entries`
const assetsPath = `${basePath}/assets`

const localeEntries = `
query getLocaleEntries {
  entries @rest(type: "Entry", path: "${basePath}/locales?${accessPath}") {
   items
   includes
  }
}
`

const textEntry = `
query getTextEntry($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`

// /spaces/3yvivwi0yvy3/environments/development/entries?access_token=R7cvbynHdtX7qhZ5Kk4p0IM_wTzBx0Z60HLDjyZQrT8&content_type=company
const entriesByContentTypeId = `
query getentriesByContentTypeId($id: String!, $locale: String!) {
  entries(id: $id, locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&locale={args.locale}&include=3") {
    items
    includes
  }
}
`

// &fields.tags[in]=accessories,flowers
const entriesByContentTypeIdAndEntryIds = `
query getentriesByContentTypeIdAndEntryIds($id: String!, $ids: String!, $locale: String!) {
  entries(id: $id, ids: $ids, locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type={args.id}&sys.id[in]={args.ids}&locale={args.locale}&include=3") {
    items
    includes
  }
}
`
const navigationItems = `
query getNavItems($id: String!, $ids: String!) {
  entries(id: $id, ids: $ids) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=navigation&fields.placement[match]='Projects Header'&include=2") {
    items
    includes
  }
}
`
const columns = `
query getColumns($id: String!, $ids: String!) {
  entries(id: $id, ids: $ids) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=column&sys.id[in]={args.ids}&include=2") {
    items
  }
}
`
const listItems = `
query getListItems($id: String!, $ids: String!) {
  entries(id: $id, ids: $ids) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&content_type=listItem&sys.id[in]={args.ids}&include=2") {
    items
    includes
  }
}
`

const entry = `
query getEntry($id: String!, $locale: String!) {
  entry(id: $id, locale: $locale) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}&locale={args.locale}") {
   id 
   contentType
   fields
  }
}
`
const reel = `
query getReel($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const media = `
query getMedia($id: String!) {
  entry(id: $id) @rest(type: "Entry", path: "${entriesPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const asset = `
query getAsset($id: String!) {
  asset(id: $id) @rest(type: "Asset", path: "${assetsPath}/{args.id}?${accessPath}") {
   id 
   contentType
   fields
  }
}
`
const linksToEntry = `
query getLinksToEntry($id: String!) {
  links(id: $id) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&links_to_entry={args.id}") {
   items
  }
}
`
const assets = `
query getAllAssets {
  assets @rest(type: "Asset", path: "${assetsPath}?${accessPath}") {
   items
  }
}
`

export const GET_LOCALE_ENTRIES = gql`
  ${localeEntries}
`

export const GET_TEXT_ENTRY = gql`
  ${textEntry}
`

export const GET_LINKS_TO_ENTRY = gql`
  ${linksToEntry}
`

export const GET_ENTRY = gql`
  ${entry}
`
export const GET_REEL = gql`
  ${reel}
`
export const GET_MEDIA = gql`
  ${media}
`

export const GET_ASSET = gql`
  ${asset}
`

export const GET_ALL_ASSETS = gql`
  ${assets}
`

export const GET_ENTRIES_BY_CONTENT_TYPE_ID = gql`
  ${entriesByContentTypeId}
`

export const GET_NAVIGATION_ITEMS = gql`
  ${navigationItems}
`

export const GET_LIST_ITEMS = gql`
  ${listItems}
`
export const GET_COLUMNS = gql`
  ${columns}
`
export const GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS = gql`
  ${entriesByContentTypeIdAndEntryIds}
`

// apollo gets buggy if you don't add ids to subqueries
const usePreviewQuery = ({
  query,
  id,
  skipBool = false,
  ids,
  locale = 'en-US',
}) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    query,
    ids
      ? {
          variables: { id, ids, locale },
          skip: skipBool,
          notifyOnNetworkStatusChange: true,
          errorPolicy: 'all',
        }
      : {
          variables: { id, locale },
          skip: skipBool,
          notifyOnNetworkStatusChange: true,
          errorPolicy: 'all',
        }
  )
  return { loading, error, data, refetch, networkStatus }
}

export default usePreviewQuery
