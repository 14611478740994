import React, { useContext } from 'react'
import { NProgressContext } from '../context'
import { useNProgressState } from '.'

export const useNProgress = () => {
  return useContext(NProgressContext)
}

export const NProgessProvider = ({ children }) => {
  const [nProgress, setNProgress] = useNProgressState({})

  const addKey = key => {
    if (key && !nProgress[key]) {
      setNProgress(nProgress => ({
        ...nProgress,
        [key]: { furthestScrollPosition: 0, furthestScrollPercentage: 0 },
      }))
    }
  }

  const addKeys = keys => {
    keys.forEach(key => {
      addKey(key)
    })
  }

  const update = (key, val) => {
    setNProgress(nProgress => ({
      ...nProgress,
      [key]: val,
    }))
  }

  return (
    <NProgressContext.Provider
      value={{ nProgress, setNProgress, addKey, addKeys, update }}
    >
      {children}
    </NProgressContext.Provider>
  )
}
