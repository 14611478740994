import { useEffect, useState } from 'react'
import useWindowReady from './useWindowReady'

const useBrowser = () => {
  const [browser, setBrowser] = useState(null)
  const windowReady = useWindowReady()
  useEffect(() => {
    if (windowReady) {
      const userAgent = window.navigator.userAgent
      let platform = null
      if (userAgent.indexOf('Chrome') !== -1) {
        platform = 'Chrome'
      } else if (userAgent.indexOf('Safari') !== -1) {
        platform = 'Safari'
      } else if (userAgent.indexOf('Firefox') !== -1) {
        platform = 'FireFox'
      } else if (
        userAgent.indexOf('MSIE') !== -1 ||
        !!document.documentMode === true
      ) {
        platform = 'IE'
      } else {
        platform = null
      }
      setBrowser(platform)
    }
  }, [windowReady])

  return { browser }
}

export default useBrowser
