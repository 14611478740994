import { useLayoutEffect } from 'react'

const useLockScroll = () => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalOverflow = window.getComputedStyle(document.body).overflow

    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalOverflow
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}

export default useLockScroll
