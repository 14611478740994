import React, { useState, useEffect, useContext } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { CognitoContext } from '../context'
const isBrowser = typeof window !== `undefined`

if (isBrowser) {
  try {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.GATSBY_AWS_REGION,
        identityPoolId: process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
        userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_WEB_ID,
      },
    })
  } catch (err) {
    console.error('error:', err)
  }
}

const useCognitoAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (!isBrowser) {
      return
    }
    try {
      // check for current session in session storage
      Auth.currentSession()
        .then(cognitoUserSession => {
          setUser(cognitoUserSession)
          setIsAuthenticated(true)
          setIsLoading(false)
          setIsError(false)
        })
        .catch(error => {
          if (error !== 'No current user') {
            setIsError(true)
            console.error({ error })
          }
          setUser(null)
          setIsLoading(false)
        })
    } catch (error) {
      setIsError(true)
      console.error({ error })
    }
  }, [])

  return {
    user,
    isAuthenticated,
    isLoading,
    isError,
  }
}

export const useAuth = () => {
  return useContext(CognitoContext)
}

export const CognitoAuthenticationProvider = ({ children }) => {
  const auth = useCognitoAuth()
  return (
    <CognitoContext.Provider value={auth}>{children}</CognitoContext.Provider>
  )
}
