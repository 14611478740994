import { useState, useEffect, useCallback } from 'react'

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percentOfPageHeight, setPercentOfPageHeight] = useState(0)
  const [furthestScrollPosition, setFurthestScrollPosition] = useState(0)

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset
    const pageHeight = Math.max(
      document.body.clientHeight - window.innerHeight,
      0
    )
    setScrollPosition(position)
    setPercentOfPageHeight(pageHeight === 0 ? 0 : position / pageHeight)
    if (position > furthestScrollPosition) {
      setFurthestScrollPosition(position)
    }
  }, [furthestScrollPosition])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return {
    scrollPosition,
    percentOfPageHeight,
    furthestScrollPosition,
  }
}

export default useScrollPosition
