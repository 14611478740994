import React, { useEffect, useState, useContext } from 'react'
import { BladeIntersectionContext } from '../context'

const useIntersection = options => {
  const { setCurrentIntersection } = useContext(BladeIntersectionContext)

  useEffect(() => {
    let currentBlades = document.querySelectorAll('.blade')
    let currentIntersection = currentBlades?.[0]
    setCurrentIntersection({ target: currentIntersection })

    const shouldUpdate = entry => {
      if (entry === currentIntersection) return false

      const entryRect = entry?.getBoundingClientRect()
      const currRect = currentIntersection?.getBoundingClientRect()

      // current intersection is not visible in viewport, should not be in calculations
      // => new entry should be updated
      if (currRect?.top >= window?.innerHeight || currRect?.bottom <= 0) {
        return true
      }

      let entryAtBottom = false
      let entryHeight = 0
      let currHeight = 0

      if (entryRect?.top > 0) {
        entryAtBottom = true
      }

      if (entryAtBottom) {
        entryHeight = window.innerHeight - currRect?.bottom
        currHeight = currRect?.bottom
      } else {
        entryHeight = entryRect?.bottom
        currHeight = window.innerHeight - entryRect?.bottom
      }

      return entryHeight > currHeight
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && shouldUpdate(entry?.target)) {
          currentIntersection = entry.target
          setCurrentIntersection(entry)
        }
      })
    }, options)

    if (options?.preview) {
      setTimeout(() => {
        if (!currentBlades?.length) {
          currentBlades = document.querySelectorAll('.blade')
          currentBlades.forEach(blade => {
            observer.observe(blade)
          })
        }
      }, 3000)
    } else {
      currentBlades.forEach(blade => {
        observer.observe(blade)
      })
    }

    return () => {
      currentBlades.forEach(blade => {
        observer.unobserve(blade)
      })
    }
  }, [options?.preview])
}

export const useIntersectionContext = () => {
  return useContext(BladeIntersectionContext)
}

export const IntersectionProvider = ({ children }) => {
  const [currentIntersection, setCurrentIntersection] = useState(null)

  return (
    <BladeIntersectionContext.Provider
      value={{ currentIntersection, setCurrentIntersection }}
    >
      {children}
    </BladeIntersectionContext.Provider>
  )
}

export default useIntersection
