import { useState, useEffect, useRef, useCallback } from 'react'

const usePortal = ({ closeOnEsc, closeOnOutsideClick }) => {
  const [active, setActive] = useState(false)
  const modalRef = useRef(null)

  const closePortal = useCallback(() => {
    if (!active) {
      return
    }
    setActive(false)
  }, [active])

  const handleOutsideMouseClick = useCallback(
    e => {
      if (!active) {
        return
      }
      const root =
        modalRef && (modalRef?.current || modalRef?.current?.defaultNode)
      if (!root || root.contains(e.target) || (e.button && e.button !== 0)) {
        return
      }
      closePortal()
    },
    [active, closePortal]
  )

  const handleKeydown = useCallback(
    e => {
      if (
        (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) &&
        active
      ) {
        closePortal()
      }

      if (modalRef.current) {
        // Focus trap with all elements within modal
        if (e.keyCode === 9 || e.key === 'Tab') {
          const target = e.target
          // Grab all elements within modal that user can tab through and are visible in UI
          const modalElements = Array.from(
            modalRef.current.querySelectorAll('button')
          ).filter(m => m.offsetParent)

          const firstElement = modalElements[0]
          const lastElement = modalElements[modalElements.length - 1]
          firstElement.focus()

          if (e.shiftKey && target === firstElement) {
            // User clicks shift and e.target is the first element then cycle back to last focusable element
            e.preventDefault()
            lastElement.focus()
          }
        }
      }
    },
    [active, closePortal]
  )

  useEffect(() => {
    if (closeOnEsc) {
      document.addEventListener('keydown', handleKeydown)
    }
    if (closeOnOutsideClick) {
      document.addEventListener('click', handleOutsideMouseClick)
    }

    return () => {
      if (closeOnEsc) {
        document.removeEventListener('keydown', handleKeydown)
      }
      if (closeOnOutsideClick) {
        document.removeEventListener('click', handleOutsideMouseClick)
      }
    }
  }, [closeOnEsc, closeOnOutsideClick, handleKeydown, handleOutsideMouseClick])

  const openPortal = e => {
    if (active) {
      return
    }
    if (e && e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation()
    }
    setActive(true)
  }

  return { openPortal, closePortal, isOpen: active, modalRef }
}

export default usePortal
