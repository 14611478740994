import React, { useContext } from 'react'
import { AuthenticationContext } from '../context'
import { useLoginState, useProdState } from './index'

const useAuthState = () => {
  const prod = process.env.GATSBY_DISPLAY_QA_LOGIN || true
  const [isAuthenticated, setIsAuthenticated] = useLoginState(false)
  const [production, setProd] = useProdState(prod)

  return {
    isAuthenticated,
    setIsAuthenticated,
    production,
    setProd,
  }
}

export const useLogin = () => {
  return useContext(AuthenticationContext)
}

export const AuthenticationProvider = ({ children }) => {
  const auth = useAuthState()
  return (
    <AuthenticationContext.Provider value={{ auth }}>
      {children}
    </AuthenticationContext.Provider>
  )
}
