import React, { useContext } from 'react'
import { LocaleContext } from '../context'

export const useLocale = () => {
  return useContext(LocaleContext)
}

export const LocaleProvider = ({ children, locale }) => {
  const localeSlug = locale === 'en-US' ? '' : `${locale}`
  const getLocalizedSlug = slug => (localeSlug ? `${localeSlug}/${slug}` : slug)

  const getLocalizedFields = arr =>
    arr.filter(({ node_locale }) => node_locale === locale)?.[0]

  return (
    <LocaleContext.Provider
      value={{ locale, localeSlug, getLocalizedSlug, getLocalizedFields }}
    >
      {children}
    </LocaleContext.Provider>
  )
}
