import {
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useDebugValue,
} from 'react'
import createPersistedGlobalState from './useGlobalState'

import useDarkMode from './useDarkMode.js'
import useMobile from './useMobile'
import useComponentTheme from './useComponentTheme.js'
import useHover from './useHover.js'
import usePreviewQuery from './usePreviewQuery.js'
import useWindowReady from './useWindowReady'
import useAcceptedTermsState from './useAcceptedTermsState'
import useLockBodyScroll from './useLockBodyScroll'
import useFullScreen from './useFullScreen'
import { useAuth } from './useAuth'
import { useLogin } from './useLogin'
import useScrollPosition from './useScrollPosition'
import { useNProgress, NProgessProvider } from './useNProgress'
import useIntersection from './useIntersection'
import { useVideoPlayer } from './useVideoPlayer'
import useLockScroll from './useLockScroll'
import usePortal from './usePortal'
import { useLocale, LocaleProvider } from './useLocale'
import useBrowser from './useBrowser'
import useErrorFocus from './useErrorFocus'
import useMediaQuery from './useMediaQuery'

export const useVisitedState = createPersistedGlobalState('visited')
export const useSubNavOpenState = createPersistedGlobalState('open')
export const useActiveProjectIdState = createPersistedGlobalState(
  'activeProjectId'
)

export const useSubNavIndexState = createPersistedGlobalState('subNavIndex')
export const useLoginState = createPersistedGlobalState('authenticated')
export const useProdState = createPersistedGlobalState('auth')

export const useNProgressState = createPersistedGlobalState('nProgress')

export {
  useDarkMode,
  useComponentTheme,
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useMediaQuery,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useDebugValue,
  useMobile,
  useHover,
  usePreviewQuery,
  useWindowReady,
  useAcceptedTermsState,
  useLockBodyScroll,
  useFullScreen,
  useAuth,
  useLogin,
  useScrollPosition,
  useNProgress,
  NProgessProvider,
  useIntersection,
  useVideoPlayer,
  useLockScroll,
  usePortal,
  useLocale,
  LocaleProvider,
  useBrowser,
  useErrorFocus,
}
